import { ContextDefinition } from "jsonld";

/**
 * =============================================================================
 * typeIndexContext: JSONLD Context for typeIndex
 * =============================================================================
 */
export const typeIndexContext: ContextDefinition = {
  type: {
    "@id": "@type",
  },
  TypeRegistration: "http://www.w3.org/ns/solid/terms#TypeRegistration",
  forClass: {
    "@id": "http://www.w3.org/ns/solid/terms#forClass",
    "@type": "@id",
  },
  instance: {
    "@id": "http://www.w3.org/ns/solid/terms#instance",
    "@type": "@id",
    "@container": "@set",
  },
  instanceContainer: {
    "@id": "http://www.w3.org/ns/solid/terms#instanceContainer",
    "@type": "@id",
    "@container": "@set",
  },
};
