import { ContextDefinition } from "jsonld";

/**
 * =============================================================================
 * spoty_ldoContext: JSONLD Context for spoty_ldo
 * =============================================================================
 */
export const spoty_ldoContext: ContextDefinition = {
  type: {
    "@id": "@type",
    "@container": "@set",
  },
  Sentence: "https://w3id.org/SpOTy/ontology#Sentence",
  identifier: {
    "@id": "https://schema.org/identifier",
    "@type": "http://www.w3.org/2001/XMLSchema#string",
  },
  language: {
    "@id": "https://w3id.org/SpOTy/ontology#language",
    "@type": "@id",
  },
  P220: {
    "@id": "http://www.wikidata.org/prop/direct/P220",
    "@type": "http://www.w3.org/2001/XMLSchema#string",
    "@container": "@set",
  },
  label: {
    "@id": "http://www.w3.org/2000/01/rdf-schema#label",
    "@type": "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString",
  },
  macroarea: {
    "@id": "https://w3id.org/SpOTy/ontology#macroarea",
    "@type": "http://www.w3.org/2001/XMLSchema#string",
  },
  genus: {
    "@id": "https://w3id.org/SpOTy/ontology#genus",
    "@type": "http://www.w3.org/2001/XMLSchema#string",
  },
  phylum: {
    "@id": "https://w3id.org/SpOTy/ontology#phylum",
    "@type": "http://www.w3.org/2001/XMLSchema#string",
  },
  center: {
    "@id": "https://w3id.org/SpOTy/ontology#center",
    "@type": "@id",
  },
  longitude: {
    "@id": "https://schema.org/longitude",
    "@type": "http://www.w3.org/2001/XMLSchema#string",
  },
  latitude: {
    "@id": "https://schema.org/latitude",
    "@type": "http://www.w3.org/2001/XMLSchema#string",
  },
  orderSvo: {
    "@id": "https://w3id.org/SpOTy/ontology#order_svo",
    "@type": "http://www.w3.org/2001/XMLSchema#string",
  },
  adpositions: {
    "@id": "https://w3id.org/SpOTy/ontology#adpositions",
    "@type": "http://www.w3.org/2001/XMLSchema#string",
  },
  svc: {
    "@id": "https://w3id.org/SpOTy/ontology#svc",
    "@type": "http://www.w3.org/2001/XMLSchema#string",
  },
  extractedFrom: {
    "@id": "https://w3id.org/SpOTy/ontology#extractedFrom",
    "@type": "http://www.w3.org/2001/XMLSchema#string",
  },
  transcription: {
    "@id": "https://w3id.org/SpOTy/ontology#transcription",
    "@type": "http://www.w3.org/2001/XMLSchema#string",
  },
  orthography: {
    "@id": "https://w3id.org/SpOTy/ontology#orthography",
    "@type": "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString",
  },
  translation: {
    "@id": "https://w3id.org/SpOTy/ontology#translation",
    "@type": "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString",
  },
  source: {
    "@id": "https://w3id.org/SpOTy/ontology#source",
    "@type": "http://www.w3.org/2001/XMLSchema#string",
  },
  trajectoiresId: {
    "@id": "https://w3id.org/SpOTy/ontology#trajectoiresId",
    "@type": "http://www.w3.org/2001/XMLSchema#integer",
  },
  firstToken: {
    "@id": "https://w3id.org/SpOTy/ontology#firstToken",
    "@type": "@id",
  },
  Token: "https://w3id.org/SpOTy/ontology#Token",
  ttranscription: {
    "@id": "https://w3id.org/SpOTy/ontology#ttranscription",
    "@type": "http://www.w3.org/2001/XMLSchema#string",
  },
  originalGloss: {
    "@id": "https://w3id.org/SpOTy/ontology#originalGloss",
    "@type": "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString",
  },
  gloss: {
    "@id": "https://w3id.org/SpOTy/ontology#gloss",
    "@type": "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString",
  },
  clause: {
    "@id": "https://w3id.org/SpOTy/ontology#clause",
    "@type": "http://www.w3.org/2001/XMLSchema#integer",
  },
  nextAnnotatedToken: {
    "@id": "https://w3id.org/SpOTy/ontology#nextAnnotatedToken",
    "@type": "@id",
  },
  morphoSyntax: {
    "@id": "https://w3id.org/SpOTy/ontology#morphoSyntax",
    "@type": "@id",
    "@container": "@set",
  },
  inScheme: {
    "@id": "http://www.w3.org/2004/02/skos/core#inScheme",
    "@type": "@id",
  },
  broader: {
    "@id": "http://www.w3.org/2004/02/skos/core#broader",
    "@type": "@id",
    "@container": "@set",
  },
  code: {
    "@id": "https://w3id.org/SpOTy/ontology#code",
    "@type": "http://www.w3.org/2001/XMLSchema#string",
  },
  comment: {
    "@id": "http://www.w3.org/2000/01/rdf-schema#comment",
    "@type": "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString",
  },
  semantics: {
    "@id": "https://w3id.org/SpOTy/ontology#semantics",
    "@type": "@id",
    "@container": "@set",
  },
  nextToken: {
    "@id": "https://w3id.org/SpOTy/ontology#nextToken",
    "@type": "@id",
  },
  lastToken: {
    "@id": "https://w3id.org/SpOTy/ontology#lastToken",
    "@type": "@id",
  },
};
