import { ShapeType } from "@ldo/ldo";
import { spoty_ldoSchema } from "./spoty_ldo.schema";
import { spoty_ldoContext } from "./spoty_ldo.context";
import {
  Sentence,
  Language,
  Center,
  Token,
  MorphoSyntax,
  Semantics,
} from "./spoty_ldo.typings";

/**
 * =============================================================================
 * LDO ShapeTypes spoty_ldo
 * =============================================================================
 */

/**
 * Sentence ShapeType
 */
export const SentenceShapeType: ShapeType<Sentence> = {
  schema: spoty_ldoSchema,
  shape: "https://w3id.org/SpOTy/shapes-ldo#Sentence",
  context: spoty_ldoContext,
};

/**
 * Language ShapeType
 */
export const LanguageShapeType: ShapeType<Language> = {
  schema: spoty_ldoSchema,
  shape: "https://w3id.org/SpOTy/shapes-ldo#Language",
  context: spoty_ldoContext,
};

/**
 * Center ShapeType
 */
export const CenterShapeType: ShapeType<Center> = {
  schema: spoty_ldoSchema,
  shape: "https://w3id.org/SpOTy/shapes-ldo#Center",
  context: spoty_ldoContext,
};

/**
 * Token ShapeType
 */
export const TokenShapeType: ShapeType<Token> = {
  schema: spoty_ldoSchema,
  shape: "https://w3id.org/SpOTy/shapes-ldo#Token",
  context: spoty_ldoContext,
};

/**
 * MorphoSyntax ShapeType
 */
export const MorphoSyntaxShapeType: ShapeType<MorphoSyntax> = {
  schema: spoty_ldoSchema,
  shape: "https://w3id.org/SpOTy/shapes-ldo#MorphoSyntax",
  context: spoty_ldoContext,
};

/**
 * Semantics ShapeType
 */
export const SemanticsShapeType: ShapeType<Semantics> = {
  schema: spoty_ldoSchema,
  shape: "https://w3id.org/SpOTy/shapes-ldo#Semantics",
  context: spoty_ldoContext,
};
