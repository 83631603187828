import { Schema } from "shexj";

/**
 * =============================================================================
 * spoty_ldoSchema: ShexJ Schema for spoty_ldo
 * =============================================================================
 */
export const spoty_ldoSchema: Schema = {
  type: "Schema",
  shapes: [
    {
      id: "https://w3id.org/SpOTy/shapes-ldo#Sentence",
      type: "ShapeDecl",
      shapeExpr: {
        type: "Shape",
        expression: {
          type: "EachOf",
          expressions: [
            {
              type: "TripleConstraint",
              predicate: "http://www.w3.org/1999/02/22-rdf-syntax-ns#type",
              valueExpr: {
                type: "NodeConstraint",
                values: ["https://w3id.org/SpOTy/ontology#Sentence"],
              },
            },
            {
              type: "TripleConstraint",
              predicate: "http://www.w3.org/1999/02/22-rdf-syntax-ns#type",
              valueExpr: {
                type: "NodeConstraint",
                nodeKind: "iri",
              },
              min: 0,
              max: -1,
            },
            {
              type: "TripleConstraint",
              predicate: "https://schema.org/identifier",
              valueExpr: {
                type: "NodeConstraint",
                datatype: "http://www.w3.org/2001/XMLSchema#string",
              },
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#language",
              valueExpr: "https://w3id.org/SpOTy/shapes-ldo#Language",
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#extractedFrom",
              valueExpr: {
                type: "NodeConstraint",
                datatype: "http://www.w3.org/2001/XMLSchema#string",
              },
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#transcription",
              valueExpr: {
                type: "NodeConstraint",
                datatype: "http://www.w3.org/2001/XMLSchema#string",
              },
              min: 0,
              max: 1,
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#orthography",
              valueExpr: {
                type: "NodeConstraint",
                datatype:
                  "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString",
              },
              min: 0,
              max: 1,
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#translation",
              valueExpr: {
                type: "NodeConstraint",
                datatype:
                  "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString",
              },
              min: 0,
              max: 1,
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#source",
              valueExpr: {
                type: "NodeConstraint",
                datatype: "http://www.w3.org/2001/XMLSchema#string",
              },
              min: 0,
              max: 1,
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#trajectoiresId",
              valueExpr: {
                type: "NodeConstraint",
                datatype: "http://www.w3.org/2001/XMLSchema#integer",
              },
              min: 0,
              max: 1,
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#firstToken",
              valueExpr: "https://w3id.org/SpOTy/shapes-ldo#Token",
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#lastToken",
              valueExpr: "https://w3id.org/SpOTy/shapes-ldo#Token",
            },
          ],
        },
      },
    },
    {
      id: "https://w3id.org/SpOTy/shapes-ldo#Language",
      type: "ShapeDecl",
      shapeExpr: {
        type: "Shape",
        expression: {
          type: "EachOf",
          expressions: [
            {
              type: "TripleConstraint",
              predicate: "http://www.wikidata.org/prop/direct/P220",
              valueExpr: {
                type: "NodeConstraint",
                datatype: "http://www.w3.org/2001/XMLSchema#string",
              },
              min: 1,
              max: -1,
            },
            {
              type: "TripleConstraint",
              predicate: "http://www.w3.org/1999/02/22-rdf-syntax-ns#type",
              valueExpr: {
                type: "NodeConstraint",
                nodeKind: "iri",
              },
              min: 0,
              max: -1,
            },
            {
              type: "TripleConstraint",
              predicate: "http://www.w3.org/2000/01/rdf-schema#label",
              valueExpr: {
                type: "NodeConstraint",
                datatype:
                  "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString",
              },
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#macroarea",
              valueExpr: {
                type: "NodeConstraint",
                datatype: "http://www.w3.org/2001/XMLSchema#string",
              },
              min: 0,
              max: 1,
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#genus",
              valueExpr: {
                type: "NodeConstraint",
                datatype: "http://www.w3.org/2001/XMLSchema#string",
              },
              min: 0,
              max: 1,
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#phylum",
              valueExpr: {
                type: "NodeConstraint",
                datatype: "http://www.w3.org/2001/XMLSchema#string",
              },
              min: 0,
              max: 1,
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#center",
              valueExpr: "https://w3id.org/SpOTy/shapes-ldo#Center",
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#order_svo",
              valueExpr: {
                type: "NodeConstraint",
                datatype: "http://www.w3.org/2001/XMLSchema#string",
              },
              min: 0,
              max: 1,
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#adpositions",
              valueExpr: {
                type: "NodeConstraint",
                datatype: "http://www.w3.org/2001/XMLSchema#string",
              },
              min: 0,
              max: 1,
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#svc",
              valueExpr: {
                type: "NodeConstraint",
                datatype: "http://www.w3.org/2001/XMLSchema#string",
              },
              min: 0,
              max: 1,
            },
          ],
        },
      },
    },
    {
      id: "https://w3id.org/SpOTy/shapes-ldo#Center",
      type: "ShapeDecl",
      shapeExpr: {
        type: "Shape",
        expression: {
          type: "EachOf",
          expressions: [
            {
              type: "TripleConstraint",
              predicate: "https://schema.org/longitude",
              valueExpr: {
                type: "NodeConstraint",
                datatype: "http://www.w3.org/2001/XMLSchema#string",
              },
            },
            {
              type: "TripleConstraint",
              predicate: "https://schema.org/latitude",
              valueExpr: {
                type: "NodeConstraint",
                datatype: "http://www.w3.org/2001/XMLSchema#string",
              },
            },
          ],
        },
      },
    },
    {
      id: "https://w3id.org/SpOTy/shapes-ldo#Token",
      type: "ShapeDecl",
      shapeExpr: {
        type: "Shape",
        expression: {
          type: "EachOf",
          expressions: [
            {
              type: "TripleConstraint",
              predicate: "http://www.w3.org/1999/02/22-rdf-syntax-ns#type",
              valueExpr: {
                type: "NodeConstraint",
                values: ["https://w3id.org/SpOTy/ontology#Token"],
              },
            },
            {
              type: "TripleConstraint",
              predicate: "http://www.w3.org/1999/02/22-rdf-syntax-ns#type",
              valueExpr: {
                type: "NodeConstraint",
                nodeKind: "iri",
              },
              min: 0,
              max: -1,
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#ttranscription",
              valueExpr: {
                type: "NodeConstraint",
                datatype: "http://www.w3.org/2001/XMLSchema#string",
              },
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#originalGloss",
              valueExpr: {
                type: "NodeConstraint",
                datatype:
                  "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString",
              },
              min: 0,
              max: 1,
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#gloss",
              valueExpr: {
                type: "NodeConstraint",
                datatype:
                  "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString",
              },
              min: 0,
              max: 1,
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#clause",
              valueExpr: {
                type: "NodeConstraint",
                datatype: "http://www.w3.org/2001/XMLSchema#integer",
                mininclusive: 1,
              },
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#nextAnnotatedToken",
              valueExpr: {
                type: "NodeConstraint",
                nodeKind: "iri",
              },
              min: 0,
              max: 1,
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#morphoSyntax",
              valueExpr: "https://w3id.org/SpOTy/shapes-ldo#MorphoSyntax",
              min: 0,
              max: -1,
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#semantics",
              valueExpr: "https://w3id.org/SpOTy/shapes-ldo#Semantics",
              min: 0,
              max: -1,
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#nextToken",
              valueExpr: "https://w3id.org/SpOTy/shapes-ldo#Token",
              min: 0,
              max: 1,
            },
            {
              type: "TripleConstraint",
              inverse: true,
              predicate: "https://w3id.org/SpOTy/ontology#lastToken",
              valueExpr: {
                type: "NodeConstraint",
                nodeKind: "iri",
              },
            },
          ],
        },
      },
    },
    {
      id: "https://w3id.org/SpOTy/shapes-ldo#MorphoSyntax",
      type: "ShapeDecl",
      shapeExpr: {
        type: "Shape",
        expression: {
          type: "EachOf",
          expressions: [
            {
              type: "TripleConstraint",
              predicate: "http://www.w3.org/2004/02/skos/core#inScheme",
              valueExpr: {
                type: "NodeConstraint",
                nodeKind: "iri",
              },
              min: 0,
              max: 1,
            },
            {
              type: "TripleConstraint",
              predicate: "http://www.w3.org/2004/02/skos/core#broader",
              valueExpr: "https://w3id.org/SpOTy/shapes-ldo#MorphoSyntax",
              min: 0,
              max: -1,
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#code",
              valueExpr: {
                type: "NodeConstraint",
                datatype: "http://www.w3.org/2001/XMLSchema#string",
              },
            },
            {
              type: "TripleConstraint",
              predicate: "http://www.w3.org/2000/01/rdf-schema#label",
              valueExpr: {
                type: "NodeConstraint",
                datatype:
                  "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString",
              },
            },
            {
              type: "TripleConstraint",
              predicate: "http://www.w3.org/2000/01/rdf-schema#comment",
              valueExpr: {
                type: "NodeConstraint",
                datatype:
                  "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString",
              },
            },
          ],
        },
      },
    },
    {
      id: "https://w3id.org/SpOTy/shapes-ldo#Semantics",
      type: "ShapeDecl",
      shapeExpr: {
        type: "Shape",
        expression: {
          type: "EachOf",
          expressions: [
            {
              type: "TripleConstraint",
              predicate: "http://www.w3.org/2004/02/skos/core#inScheme",
              valueExpr: {
                type: "NodeConstraint",
                nodeKind: "iri",
              },
              min: 0,
              max: 1,
            },
            {
              type: "TripleConstraint",
              predicate: "http://www.w3.org/2004/02/skos/core#broader",
              valueExpr: "https://w3id.org/SpOTy/shapes-ldo#Semantics",
              min: 0,
              max: -1,
            },
            {
              type: "TripleConstraint",
              predicate: "https://w3id.org/SpOTy/ontology#code",
              valueExpr: {
                type: "NodeConstraint",
                datatype: "http://www.w3.org/2001/XMLSchema#string",
              },
              min: 0,
              max: 1,
            },
            {
              type: "TripleConstraint",
              predicate: "http://www.w3.org/2000/01/rdf-schema#label",
              valueExpr: {
                type: "NodeConstraint",
                datatype:
                  "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString",
              },
              min: 0,
              max: 1,
            },
            {
              type: "TripleConstraint",
              predicate: "http://www.w3.org/2000/01/rdf-schema#comment",
              valueExpr: {
                type: "NodeConstraint",
                datatype:
                  "http://www.w3.org/1999/02/22-rdf-syntax-ns#langString",
              },
              min: 0,
              max: 1,
            },
          ],
        },
      },
    },
  ],
};
